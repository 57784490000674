import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import {ThemeProvider} from 'styled-components';

import Header from './Header/Header';
import Footer from './Footer/Footer';

import '../../styles/layout.css';

const theme = {
	main: {
		fonts: {
			displayXLarge: {
				fontFamily: 'Libre Franklin',
				fontSize: '48px',
				fontWeight: 500,
				color: '#212b36'
			},
			displayLarge: {
				fontFamily: 'Libre Franklin',
				fontSize: '36px',
				fontWeight: 500,
				color: '#212b36'
			},
			displayLargeG: {
				fontFamily: 'Libre Franklin',
				fontSize: '36px',
				fontWeight: 500,
				color: '#919eab'
			},
			displayMedium: {
				fontFamily: 'Libre Franklin',
				fontSize: '24px',
				fontWeight: 500,
				color: '#627280'
			},
			displaySmall: {
				fontFamily: 'Libre Franklin',
				fontSize: '20px',
				fontWeight: 500,
				color: '#212b36'
			},
			heading: {
				fontFamily: 'Libre Franklin',
				fontSize: '18px',
				fontWeight: 600,
				color: '#212b36'
			},
			subheading: {
				fontFamily: 'Libre Franklin',
				fontSize: '12px',
				fontWeight: 600,
				color: '#c5cdd5'
			},
			body: {
				fontFamily: 'Libre Franklin',
				fontSize: '16px',
				color: '#454f5b'
			},
			textLink: {
				fontFamily: 'Libre Franklin',
				fontSize: '14px',
				color: '#212b36'
			},
			headingLight: {
				fontFamily: 'Libre Franklin',
				fontSize: '14px',
				fontWeight: 300,
				color: '#919eab'
			},
			bodyLight: {
				fontFamily: 'Libre Franklin',
				fontSize: '18px',
				fontWeight: 300,
				color: '#212b36'
			}

		},
		colors: {
			blue: {
				lighter: '#61bfff',
				light: '#28a8ff',
				regular: '#007ace',
				dark: '#0464a7',
				text: '#02263e'
			},
			orange: {
				lighter: '#ff9553',
				regular: '#f56d18',
				dark: '#d25202',
				text: '#431b02'
			},
			yellow: {
				regular: '#f9e24e'
			},
			green: {
				regular: '#108043'
			},
			grey: {
				lighter: '#f9fafb',
				light: '#f4f6f8',
				regular: '#dfe3e9',
				dark: '#c5cdd5',
				darker: '#919eab',
				slate: '#627280',
				fishscale: '#454f5b',
				squidink: '#212b36'
			}
		}
	},
	breakpoints: {
	  xs: 0,
	  sm: 576,
	  md: 768,
	  lg: 992,
	  xl: 1200,
	},
};

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	return (
		<ThemeProvider theme={theme}>
			<div>
				<Header siteTitle={data.site.siteMetadata.title} />
				<main>{children}</main>
				<Footer />
			</div>
		</ThemeProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
